import React from 'react';

import { FeaturesSlider } from '@latitude/features-slider';
import { BUTTON_STYLE } from '@latitude/core/utils/constants';
import { Link } from '@latitude/link';

const RESPONSIVE_SETTINGS = [
  {
    breakpoint: 10000,
    settings: {
      slidesToShow: 4,
      slidesToScroll: 1
    }
  },
  {
    breakpoint: 1024,
    settings: {
      slidesToShow: 4,
      slidesToScroll: 1
    }
  },
  {
    breakpoint: 992,
    settings: {
      slidesToShow: 3,
      slidesToScroll: 1
    }
  },
  {
    breakpoint: 768,
    settings: {
      slidesToShow: 2,
      slidesToScroll: 1
    }
  },
  {
    breakpoint: 450,
    settings: {
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false
    }
  }
];

function QuickLinks(props) {
  const { heading, data } = props;
  return (
    <div
      css={`
        && {
          padding-bottom: 0;
          background-color: #f8f8f8;
        }
        && div.slick-slide div.lfs-card {
          background-color: #fff;
          border: 1px solid #ccc;
          border-bottom: 2px solid #ccc;
          padding: 10px;
        }
        && div.slick-slide div.lfs-card div.lfs-card-icon {
          width: 60px !important;
          height: 60px !important;
        }
        && div.slick-slide div.lfs-card div.text {
          height: 120px;
        }
        && div.slick-slide div.lfs-card a {
          width: fit-content;
          margin: 10px auto;
        }
      `}
    >
      <FeaturesSlider
        className="lets-explore"
        heading={heading}
        id="lets-explore"
        data={data.map((card, index) => {
          return {
            icon: card.icon,
            title: card.title,
            jsx: (
              <>
                <div className="text">{card.text}</div>
                {card.href && (
                  <>
                    <Link
                      button={BUTTON_STYLE.TERTIARY}
                      href={card.href}
                      trackId={`quicklinks-${card.trackId}`}
                      trackEventData={{
                        card: card.title,
                        location: heading
                      }}
                    >
                      Learn more
                    </Link>
                  </>
                )}
              </>
            )
          };
        })}
        responsiveSettings={RESPONSIVE_SETTINGS}
      />
    </div>
  );
}

export default QuickLinks;
